<template>
  <div>
    <h2>Отправленные чеки</h2>
    <form action="" class="personal__form personal__form--search">
      <div
        class="
          form__row
          row
          position-relative
          justify-content-start
          justify-content-xl-between
          d-none
          d-xl-inline-flex
          w-100
        "
      >
        <div class="col-lg-4 d-none d-xl-block">
          <label for="date">Дата</label>
          <div class="d-flex justify-content-between personal__form--date">
            <input
              type="date"
              v-model="filter.dateStart"
              class="form__input personal__input"
            >
            <input
              type="date"
              v-model="filter.dateEnd"
              class="form__input personal__input"
            >
          </div>
        </div>
        <div class="col-lg-6 d-none d-xl-block">
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              pl-4
              personal__form--persdata"
          >
            <div>
              <label>ФИО</label>
              <input
                type="text"
                class="personal__input"
                v-model="filter.name"
              >
            </div>
            <div>
              <label>Почта</label>
              <input
                type="text"
                class="personal__input"
                v-model="filter.email"
              >
            </div>
          </div>
        </div>
        <div class="col-lg-2 justify-content-between align-items-end d-none d-xl-flex">
          <div
            class="btn btn--small btn--red btn--search"
            @click.prevent="applyFilter()"
          >Найти</div>

          <div
            class="cross"
            @click.prevent="clear()"
          >
            <img src="icons/cross.svg" alt="" class="cross-icon">
          </div>
        </div>
      </div>
      <div class="form__row row d-xl-none">
        <div class="filter-btn">
          <div class="burger mobile-btn filter-burger"><span></span></div>
          Фильтры
        </div>
        <button class="filter-btn">
          Сбросить
          <div class="cross">
            <img src="icons/cross.svg" alt="" class="cross-icon">
          </div>
        </button>
      </div>
    </form>
    <div class="personal__table overflow-x">
      <table>
        <tr>
          <th><div><b>Дата</b></div></th>
          <th><div><b>ФИО клиента</b></div></th>
          <th><div><b>Сумма</b></div></th>
          <th><div><b>Электронная почта</b></div></th>
          <th><div><b>Скачать файл</b></div></th>
        </tr>

        <template v-for="(item, index) in receiptList">
          <tr :key="index">
            <td><div>{{ item.date }}</div></td>
            <td><div>{{ item.name }}</div></td>
            <td><div>{{ +item.sum * + item.count }} руб.</div></td>
            <td><div>{{ item.email }}</div></td>
            <td>
              <div>
                <a
                  :href="item.file"
                  class="btn"
                  target="_blank"
                >Скачать файл</a></div>
            </td>
          </tr>
        </template>
      </table>
    </div>

    <div class="text-center">
      <a
        href="#"
        class="btn mt-3"
        @click.prevent="getReceiptList()"
        v-if="moreBtnShow && !btnLoading"
      >Загрузить еще</a>

      <Preloader v-if="btnLoading"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'ReceiptList',
  components: {
    Preloader,
  },
  data() {
    return {
      receiptList: [],
      filter: {
        dateStart: '',
        dateEnd: '',
        name: '',
        email: '',
      },
      limit: 20,
      offset: 0,
      moreBtnShow: true,
      btnLoading: true,
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    clear() {
      this.filter = {
        dateStart: '',
        dateEnd: '',
        name: '',
        email: '',
      };
    },
    applyFilter() {
      this.offset = 0;
      this.receiptList = [];
      this.moreBtnShow = true;
      this.getReceiptList();
    },
    getReceiptList() {
      this.btnLoading = true;

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getReceiptList');
      url.searchParams.set('limit', this.limit);
      url.searchParams.set('offset', this.offset);

      const keys = Object.keys(this.filter);
      keys.forEach((val) => {
        url.searchParams.set(`filter[${val}]`, this.filter[val]);
      });

      axios
        .get(url)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.receiptList = this.receiptList.concat(response.data.data);
            this.offset += this.limit;
          } else {
            this.moreBtnShow = false;
          }

          this.btnLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getReceiptList();
  },
};
</script>
