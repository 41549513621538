<template>
  <section class="tabs-content personal__content tabs-content--active">
    <AddReceipt />

    <ReceiptList />
  </section>
</template>

<script>
import AddReceipt from '@/components/AddReceipt.vue';
import ReceiptList from '@/components/ReceiptList.vue';

export default {
  name: 'SendReceipt',
  components: {
    AddReceipt,
    ReceiptList,
  },
  computed: {
    userGroup() {
      return this.$store.getters.getUserGroup;
    },
  },
  created() {
    if (this.userGroup === 'methodology') {
      this.$router.push('/personal/methodology/');
    }
  },
};
</script>
