<template>
  <form class="form personal__form" @submit.prevent="submit">
    <template v-if="errorMessage">
      <p>{{ errorMessage }}</p>
    </template>

    <template v-if="successMessage">
      <p>{{ successMessage }}</p>
    </template>

    <div class="form__row row">
      <div class="col-lg-4 mb-4">
        <label>ФИО клиента</label>
        <div class="input-search">
          <input
            type="text"
            class="personal__input personal__form-text"
            v-model="searchClient"
            @click="showClientList = true"
          />

          <ul
            class="personal__input"
            required
            v-if="showClientList"
          >
            <template v-for="(item, index) in clientListComp">
              <li
                :key="index"
                @click="setSelectClient(item)"
              >{{ item.name }}</li>
            </template>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 mb-4">
        <label>Электронная почта</label>
        <input
          type="text"
          placeholder="Е-mail"
          class="personal__input personal__form-text"
          required
          disabled
          :value="email"
        >
      </div>

      <div class="col-lg-4 mb-4">
        <label>Тип операции</label>
        <select
          class="personal__input"
          required
          v-model="arival"
        >
          <template v-for="(item, index) in arivalList">
            <option
              :value="item"
              :key="index"
            >{{ item }}</option>
          </template>
        </select>
      </div>

      <div class="col-lg-4 mb-4">
        <label>Адрес филиала</label>
        <select
          class="personal__input"
          required
          v-model="branch"
        >
          <template v-for="(item, index) in branchList">
            <option
              :value="item.address"
              :key="index"
            >{{ item.address }}</option>
          </template>
        </select>
      </div>

      <div class="col-lg-4 mb-4">
        <label>Сумма</label>
        <select
          class="personal__input"
          required
          :disabled="checkCustomSum"
          v-model="sum"
        >
          <template v-for="(item, index) in priceList">
            <option
              :value="item.price"
              :key="index"
            >{{ item.price }}</option>
          </template>
        </select>
      </div>

      <div class="col-lg-4 mb-4">
        <label>Кол-во уроков</label>
        <input
          type="text"
          placeholder="0"
          class="personal__input personal__form-text"
          required
          :disabled="checkCustomSum"
          v-model="count"
        >
      </div>
    </div>

    <div class="form__row row">
      <div
        class="
          col-lg-2 d-flex
          personal__row-chb
          align-items-center
          align-items-lg-end
          form__checkbox position-relative
        "
      >
        <input
          type="checkbox"
          id="vruchnuyu"
          class="personal__input personal__form-checkbox checkbox mt-2"
          v-model="checkCustomSum"
        >
        <label for="vruchnuyu" class=""></label>
        <label for="vruchnuyu">Ввести вручную</label>
      </div>

      <div class="col-lg-4">
        <label for="">Сумма</label>
        <input
          type="text"
          placeholder="0 руб."
          class="personal__input personal__form-text"
          :disabled="!checkCustomSum"
          v-model="customSum"
        >
      </div>

      <div class="col-lg-4">
        <label for="">Кол-во уроков</label>
        <input
          type="text"
          placeholder="0"
          class="personal__input personal__form-text"
          :disabled="!checkCustomSum"
          v-model="customCount"
        >
      </div>
    </div>

    <input
      type="submit"
      value="Отправить чек"
      class="btn btn--big personal__form-btn"
      v-if="!btnLoading"
    >
    <Preloader v-if="btnLoading"/>
  </form>
</template>

<script>
import axios from 'axios';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'AddReceipt',
  components: {
    Preloader,
  },
  data() {
    return {
      successMessage: '',
      errorMessage: '',
      btnLoading: false,
      branchList: [],
      priceList: [],
      clientList: [],
      arivalList: [
        'Приход',
        'Возврат',
      ],
      checkCustomSum: false,
      arival: 'Приход',
      branch: '',
      sum: 0,
      count: 0,
      customSum: 0,
      customCount: 0,
      selectClient: {
        name: '',
        email: '',
      },
      searchClient: '',
      showClientList: false,
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    name() {
      return this.selectClient.name;
    },
    email() {
      return this.selectClient.email;
    },
    compSum() {
      return (this.checkCustomSum) ? this.customSum : this.sum;
    },
    compCount() {
      return (this.checkCustomSum) ? this.customCount : this.count;
    },
    checkForm() {
      return (this.name && this.email && this.arival
        && this.branch && this.compSum && this.compCount) ? 1 : 0;
    },
    clientListComp() {
      return this.clientList.filter((item) => {
        const clientName = item.name.toLowerCase();
        const serchName = this.searchClient.toLowerCase();
        return clientName.indexOf(serchName) !== -1;
      });
    },
  },
  methods: {
    setSelectClient(item) {
      this.selectClient = { ...item };
      this.showClientList = false;
      this.searchClient = item.name;
    },
    getBranchList() {
      this.branchList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getBranchUserList');

      axios
        .get(url)
        .then((response) => {
          this.branchList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPriceList() {
      this.priceList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getPriceList');

      axios
        .get(url)
        .then((response) => {
          this.priceList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClientList() {
      this.clientList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getClientList');

      axios
        .get(url)
        .then((response) => {
          this.clientList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      this.errorMessage = '';
      this.successMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'addReceipt');

        const params = new URLSearchParams();
        params.append('UF_NAME', this.name);
        params.append('UF_EMAIL', this.email);
        params.append('UF_ARIVAL', this.arival);
        params.append('UF_BRANCH', this.branch);
        params.append('UF_SUM', this.compSum);
        params.append('UF_COUNT', this.compCount);

        axios
          .post(url, params)
          .then((response) => {
            const { data } = response.data;

            if (data.success) {
              this.successMessage = data.message;

              setTimeout(() => {
                this.successMessage = '';
              }, 2000);

              this.arival = '';
              this.branch = '';
              this.sum = 0;
              this.count = 0;
              this.customSum = 0;
              this.customCount = 0;
              this.selectClient = {
                name: '',
                email: '',
              };

              this.$emit('input', true);
            } else {
              this.errorMessage = data.message;
            }

            this.btnLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.errorMessage = 'Ошибка!';
      }
    },
  },
  mounted() {
    this.getBranchList();
    this.getPriceList();
    this.getClientList();
  },
};
</script>

<style lang="scss" scoped>
  .input-search {
    position: relative;

    ul {
      position: absolute;
      width: 100%;
      z-index: 2;
      background: #fff;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .5);
      list-style: none;
      margin: 0;
      padding: 5px 0;

      li {
        cursor: pointer;
        padding: 2px 25px;

        &:hover {
          background: #1E90FF;
          color: #fff;
        }
      }
    }
  }
</style>
